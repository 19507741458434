import {
  BASIC_CONSENT,
  FUNCTIONAL_CONSENT,
  DARK_MODE,
  FORM_SAVE,
} from '../cookie/CookiesInfoJson';

const getCookie = (key) => {
  // let b = document.cookie.match('(^|;)\\s*' + key + '\\s*=\\s*([^;]+)');
  return true;
  // if (b === null) {
  //   return false;
  // }
  // let bb = JSON.parse(b.pop());
  // return b ? bb : false;
};
const isBasicCookieEnabled = () => {
  return getCookie(BASIC_CONSENT);
};

const isDarkModeCookieEnabled = () => {
  return getCookie(DARK_MODE);
};
const isFormDataCookieEnabled = () => {
  return getCookie(FORM_SAVE);
};
const isFunctionalCookieEnabled = () => {
  return getCookie(FUNCTIONAL_CONSENT);
};

export {
  isBasicCookieEnabled,
  isDarkModeCookieEnabled,
  isFormDataCookieEnabled,
  isFunctionalCookieEnabled,
};
