import { SET_DARK_MODE, REFRESH, SET_FORM_SAVE, SET_SETTINGS } from '../types'; // eslint-disable-next-line*/

function SettingsReducer(state, action) {
  switch (action.type) {
    case REFRESH: {
      return {
        ...state,
        darkMode: false,
        formSave: false,
      };
    }
    case SET_DARK_MODE: {
      return {
        ...state,
        darkMode: action.payload,
      };
    }
    case SET_FORM_SAVE: {
      return {
        ...state,
        formSave: action.payload,
      };
    }
    case SET_SETTINGS: {
      return {
        ...state,
        ...action.payload,
      };
    }
    default:
      return state;
  }
}
export default SettingsReducer;
